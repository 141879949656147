import React from "react";
import { useState } from "react";
import { writeContract, getNetwork } from '@wagmi/core'
import { getArticleNFTAddress } from '../../constants/addressBook'
import articleNFTABI from '../../abis/articleNFTABI'

export const SetIssueAvailabilityArticleNFT: React.FC = () => {
  const [articleId, setArticleId] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  return (
    <div>
      <label>Set new Issue availaiblity period for an article
      <form>
        <label>Article Id
          <input
            type="text" 
            value={articleId}
            onChange={(e) => setArticleId(e.target.value)}
          />
        </label>

        <label>Start
          <input
            type="text" 
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />
        </label>

        <label>End
          <input
            type="text" 
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </label>
      </form>
      <button onClick={async () => {
        const network = await getNetwork()
        console.log(network.chain?.name)
        const articleNFTAddress = getArticleNFTAddress(network.chain?.name)
        writeContract({
          address: articleNFTAddress,
          abi: articleNFTABI,
          functionName: "setIssueAvailability",
          args: [BigInt(start), BigInt(end), BigInt(articleId)]
        })
      }}>Submit</button>
      </label>
      <br/>
      <br/>
    </div>
  )
}